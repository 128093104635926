/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { Layout } from "../components";
import { Link } from "gatsby";

const grid_limit = 12;
const list_limit = 6;

const Blog = ({ pageContext, location: { href } }) => {
  const {
    group,
    categories,
    lastPosts,
    pageCount,
    activeCategory,
    index,
    seo,
  } = pageContext;

  const next = index + 1 <= pageCount ? `/page/${index + 1}` : null;
  const prev =
    index - 1 > 0 ? (index === 2 ? "/" : `/page/${index - 1}`) : null;

  const getColorByCategory = (category) => {
    switch (category) {
      case "biznes":
        return "#00319F";
      case "branding":
        return "#b90000";
      case "marketing internetowy":
      case "marketing-internetowy":
        return "#7713c0";
      case "sklepy internetowe":
      case "sklepy-internetowe":
        return "#02b7b7";
      case "social media":
      case "social-media":
        return "#f163c7";
      case "StartUp":
      case "startup":
        return "#add601";
      case "strony internetowe":
      case "strony-internetowe":
        return "#c9c902";
      case "szkolenia":
        return "#d98503";
      case "UX":
      case "ux":
        return "#b700a9";
      default:
        return "#2D2D2D";
    }
  };

  const [heroContent, setHeroContent] = useState({
    date: lastPosts?.edges[0]?.node?.date,
    main_category: lastPosts?.edges[0]?.node?.categories[0].name,
    categories: lastPosts?.edges[0]?.node?.categories,
    path: lastPosts?.edges[0]?.node?.path,
    title: lastPosts?.edges[0]?.node?.title,
    hero: lastPosts?.edges[0]?.node?.featured_media,
    featuredImg: lastPosts?.edges[0]?.node?.acf?.hero_image,
    color: getColorByCategory(activeCategory),
  });
  const [listPosts, setListPosts] = useState(list_limit);

  useEffect(() => {
    setHeroContent({
      date: group?.[0]?.node?.date,
      main_category: activeCategory,
      categories: group?.[0]?.node?.categories,
      path: group?.[0]?.node?.path,
      title: group?.[0]?.node?.title,
      featuredImg: group?.[0]?.node?.featured_media,
      hero: group?.[0]?.node?.acf?.hero_image,
      color: getColorByCategory(activeCategory),
    });
  }, [href]);

  const grid_posts = group.slice(0, grid_limit);
  const list_posts = group.slice(grid_limit);
  const list_posts_to_show = list_posts.slice(0, listPosts);

  return (
    <Layout
      seo={{
        ...seo,
        title: "Blog",
        href: href && href.replace(`/page/${index}`, ""),
        next,
        prev,
        lang: "pl",
      }}
    >
      {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
      <div className="main-wrapper">
        <section
          className="container-fluid px-0 blog__hero"
          style={{
            background: heroContent?.color,
          }}
        >
          <div className="blog__hero-image">
            <img
              src={heroContent?.hero?.source_url}
              alt={heroContent?.hero?.alt_text}
            />
          </div>
          <div
            className="blog__hero-overlay"
            style={{
              background: heroContent?.hero ? heroContent?.color : "#2D2D2D",
            }}
          ></div>
          <div className="blog__hero-content">
            <div className="blog__hero-date">
              {heroContent?.date?.split("T")[0].split("-").reverse().join(".")}
            </div>
            <div className="blog__hero-categories">
              {lastPosts?.edges[0]?.node?.path === heroContent?.path && (
                <p
                  className="blog__hero-category blog__hero-category--newest"
                  style={{
                    background: "#FF0000",
                  }}
                >
                  najnowszy wpis
                </p>
              )}
              {heroContent?.categories?.map((category, index) => (
                <p
                  className="blog__hero-category"
                  key={index}
                  style={{
                    background: getColorByCategory(category?.name),
                  }}
                >
                  {category?.name}
                </p>
              ))}
            </div>
            <Link to={heroContent?.path}>
              <p
                className="blog__hero-title"
                dangerouslySetInnerHTML={{
                  __html: heroContent?.title,
                }}
              />
            </Link>
          </div>
        </section>

        <section className="container-fluid blog__categories">
          <div className="row">
            <div className="col-12 offset-xl-1 col-xl-10">
              <ul className="blog__categories-list">
                <li
                  className={`blog__categories-item ${
                    activeCategory === "all" ? "active" : " "
                  }`}
                  onMouseEnter={({ target }) =>
                    activeCategory !== "all" && target.classList.add("active")
                  }
                  onMouseLeave={({ target }) =>
                    activeCategory !== "all" &&
                    target.classList.remove("active")
                  }
                >
                  <Link to="/blog">Wszystko</Link>
                </li>
                {categories.map(({ node }) => (
                  // eslint-disable-next-line
                  <li
                    className={`blog__categories-item ${
                      activeCategory === node.name ? "active" : " "
                    }`}
                    key={node.id}
                    // onMouseEnter={({ target }) =>
                    //   activeCategory !== node.name && target.classList.add("active")
                    // }
                    // onMouseLeave={({ target }) =>
                    //   activeCategory !== node.name &&
                    //   target.classList.remove("active")
                    // }
                  >
                    <Link to={`/${node.slug}#list`}>{node.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        <section className="container-fluid blog__grid">
          <div className="row blog__grid-posts">
            {grid_posts?.map(({ node }) => {
              const main_category = node?.path.split("/")?.[2];
              const main_color = getColorByCategory(main_category);

              return (
                <article
                  key={node.id}
                  className="col-12 col-sm-6 col-lg-4 blog__grid-item"
                >
                  <Link
                    to={node?.path}
                    className="blog__grid-link"
                    style={{
                      background: main_color,
                    }}
                  >
                    {node?.featured_media?.source_url && (
                      <div className="blog__grid-image">
                        <img
                          src={node?.featured_media?.source_url}
                          alt={node?.featured_media?.alt_text}
                        />
                      </div>
                    )}
                    <div className="blog__grid-content">
                      <time className="blog__grid-date">
                        {node?.date
                          ?.split("T")[0]
                          .split("-")
                          .reverse()
                          .join(".")}
                      </time>
                      <div className="blog__grid-desc">
                        {node?.categories?.map((category, index) => (
                          <span
                            key={index}
                            className="blog__grid-label"
                            style={{
                              background: getColorByCategory(category?.name),
                            }}
                          >
                            {category?.name}
                          </span>
                        ))}
                        <p
                          className="blog__grid-title"
                          dangerouslySetInnerHTML={{ __html: node.title }}
                        />
                      </div>
                    </div>
                  </Link>
                </article>
              );
            })}
          </div>
        </section>

        <section className="container-fluid blog__list">
          <div className="row blog__list-posts">
            {list_posts_to_show?.map(({ node }) => {
              const main_category = node?.path.split("/")?.[2];
              const main_color = getColorByCategory(main_category);

              return (
                <article key={node.id} className="col-12 blog__list-item">
                  <Link to={node?.path} className="blog__list-link">
                    <div
                      className="blog__list-image"
                      style={{
                        background: main_color,
                      }}
                    >
                      {node?.featured_media?.source_url && (
                        <img
                          src={node?.featured_media?.source_url}
                          alt={node?.featured_media?.alt_text}
                        />
                      )}
                    </div>
                    <div className="blog__list-content">
                      <time className="blog__list-date">
                        {node?.date
                          ?.split("T")[0]
                          .split("-")
                          .reverse()
                          .join(".")}
                      </time>
                      <div className="blog__list-categories">
                        {node?.categories?.map((category, index) => (
                          <span
                            key={index}
                            className="blog__list-label"
                            style={{
                              background: getColorByCategory(category?.name),
                            }}
                          >
                            {category?.name}
                          </span>
                        ))}
                      </div>
                      <p
                        className="blog__list-title"
                        dangerouslySetInnerHTML={{ __html: node.title }}
                      />
                    </div>
                  </Link>
                </article>
              );
            })}
          </div>
          {list_posts && list_posts?.length > listPosts ? (
            <button
              type="button"
              className="btn_custom blog__list-more"
              onClick={() => setListPosts(listPosts + 6)}
            >
              Pokaż więcej
            </button>
          ) : null}
        </section>
      </div>
    </Layout>
  );
};

export default Blog;
